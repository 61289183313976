const privateRoutes = [
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/Private/Payment'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('@/views/Private/Welcome'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Private/Dashboard'),
    meta: {
      requiresAuth: true,
      module: 'games'
    }
  },
  {
    path: '/add-account',
    name: 'AddAccount',
    component: () => import('@/views/Private/AddAccount'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: () => import('@/views/Private/Subscription'),
    meta: {
      requiresAuth: true,
      module: 'subscription'
    }
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: () => import('@/views/Private/Settings/Upgrade'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/team-mates',
    name: 'TeamMates',
    component: () => import('@/views/Private/TeamMates'),
    meta: {
      requiresAuth: true,
      module: 'teams'
    }
  },
  {
    path: '/games',
    component: () => import('@/views/Private/Game/Index'),
    meta: {
      requiresAuth: true,
      module: 'games'
    },
    children: [
      {
        path: '/',
        name: 'GameList',
        component: () => import('@/views/Private/Game/List'),
        meta: {
          requiresAuth: true,
          module: 'games'
        }
      },
      {
        path: '/calendar',
        name: 'GameCalendar',
        component: () => import('@/views/Private/Game/Calendar'),
        meta: {
          requiresAuth: true,
          module: 'games'
        }
      },
      {
        path: 'add',
        name: 'GameAdd',
        component: () => import('@/views/Private/Game/Game'),
        meta: {
          requiresAuth: true,
          module: 'games'
        }
      },
      {
        path: 'edit/:id',
        name: 'GameEdit',
        component: () => import('@/views/Private/Game/Game'),
        meta: {
          requiresAuth: true,
          module: 'games'
        }
      }
    ]
  },
  {
    path: '/rewards',
    component: () => import('@/views/Private/Reward/Index'),
    meta: {
      requiresAuth: true,
      module: 'rewards'
    },
    children: [
      {
        path: '/',
        name: 'RewardList',
        component: () => import('@/views/Private/Reward/List'),
        meta: {
          requiresAuth: true,
          module: 'rewards'
        }
      },
      {
        path: 'add',
        name: 'RewardAdd',
        component: () => import('@/views/Private/Reward/Reward'),
        meta: {
          requiresAuth: true,
          module: 'rewards'
        }
      },
      {
        path: 'edit/:id',
        name: 'RewardEdit',
        component: () => import('@/views/Private/Reward/Reward'),
        meta: {
          requiresAuth: true,
          module: 'rewards'
        }
      }
    ]
  },
  {
    path: '/crm',
    component: () => import('@/views/Private/CRM/Index'),
    meta: {
      requiresAuth: true,
      module: 'games'
    },
    children: [
      {
        path: '/',
        name: 'CRMList',
        component: () => import('@/views/Private/CRM/List'),
        meta: {
          requiresAuth: true,
          module: 'games'
        }
      }
      // {
      //   path: 'add',
      //   name: 'RewardAdd',
      //   component: () => import('@/views/Private/CRM/Reward'),
      //   meta: {
      //     requiresAuth: true,
      //     module: 'crm'
      //   },
      // },
      // {
      //   path: 'edit/:id',
      //   name: 'RewardEdit',
      //   component: () => import('@/views/Private/CRM/Reward'),
      //   meta: {
      //     requiresAuth: true,
      //     module: 'crm'
      //   },
      // }
    ]
  },
  {
    path: '/settings',
    component: () => import('@/views/Private/Settings/Index'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/domains',
        name: 'Domains',
        component: () => import('@/views/Private/Settings/Domains'),
        meta: {
          requiresAuth: true,
          module: 'domains'
        }
      },
      {
        path: '/integrations',
        name: 'Integrations',
        component: () => import('@/views/Private/Settings/Integrations'),
        meta: {
          requiresAuth: true,
          module: 'integrations'
        }
      },
      {
        path: '/templates',
        name: 'Templates',
        component: () => import('@/views/Private/Settings/Templates/Index'),
        meta: {
          requiresAuth: true,
          module: 'games'
        },
        children: [
          {
            path: '/',
            name: 'TemplateList',
            component: () => import('@/views/Private/Settings/Templates/List'),
            meta: {
              requiresAuth: true,
              module: 'games'
            }
          },
          {
            path: 'game-over/add',
            name: 'GameOverTemplateAdd',
            component: () => import('@/views/Private/Settings/Templates/Template'),
            meta: {
              requiresAuth: true,
              module: 'games'
            }
          },
          {
            path: 'game-over/edit/:id',
            name: 'GameOverTemplateEdit',
            component: () => import('@/views/Private/Settings/Templates/Template'),
            meta: {
              requiresAuth: true,
              module: 'games'
            }
          },
          {
            path: 'success/add',
            name: 'SuccessTemplateAdd',
            component: () => import('@/views/Private/Settings/Templates/Template'),
            meta: {
              requiresAuth: true,
              module: 'games'
            }
          },
          {
            path: 'success/edit/:id',
            name: 'SuccessTemplateEdit',
            component: () => import('@/views/Private/Settings/Templates/Template'),
            meta: {
              requiresAuth: true,
              module: 'games'
            }
          },
          {
            path: 'leaderboard/add',
            name: 'LeaderboardTemplateAdd',
            component: () => import('@/views/Private/Settings/Templates/Template'),
            meta: {
              requiresAuth: true,
              module: 'games'
            }
          },
          {
            path: 'leaderboard/edit/:id',
            name: 'LeaderboardTemplateEdit',
            component: () => import('@/views/Private/Settings/Templates/Template'),
            meta: {
              requiresAuth: true,
              module: 'games'
            }
          }
        ]
      }
      // {
      //   path: 'add',
      //   name: 'RewardAdd',
      //   component: () => import('@/views/Private/Reward/Reward')
      // },
      // {
      //   path: 'edit/:id',
      //   name: 'RewardEdit',
      //   component: () => import('@/views/Private/Reward/Reward')
      // }
    ]
  }
]
export default privateRoutes
