<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="4" fill="url(#paint0_linear_1091_4191)" fill-opacity="0.1" />
        <g clip-path="url(#clip0_1091_4191)">
            <path
                d="M15.0909 14.4617H20.9091L18.1558 8.83301H17.8442L15.0909 14.4617ZM17.2208 27.1663V16.0698H8.25974L17.2208 27.1663ZM18.7792 27.1663L27.7403 16.0698H18.7792V27.1663ZM22.6234 14.4617H28L25.8442 10.0123C25.6537 9.65497 25.3984 9.36907 25.0784 9.15465C24.7584 8.94022 24.399 8.83301 24 8.83301H19.9221L22.6234 14.4617ZM8 14.4617H13.3766L16.0779 8.83301H12C11.6017 8.83301 11.2426 8.94022 10.9226 9.15465C10.6026 9.36907 10.347 9.65497 10.1558 10.0123L8 14.4617Z"
                fill="url(#paint1_linear_1091_4191)" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_1091_4191" x1="-3.62836e-07" y1="17.8896" x2="36" y2="17.8896"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A3494" />
                <stop offset="0.0549206" stop-color="#4F3795" />
                <stop offset="0.0924392" stop-color="#583C98" />
                <stop offset="0.2" stop-color="#6A479C" />
                <stop offset="0.364138" stop-color="#81499D" />
                <stop offset="0.450668" stop-color="#94469B" />
                <stop offset="0.5" stop-color="#A14299" />
                <stop offset="0.647401" stop-color="#CC3293" />
                <stop offset="0.780331" stop-color="#EE236C" />
                <stop offset="0.818455" stop-color="#EF4B5E" />
                <stop offset="0.859048" stop-color="#F2624E" />
                <stop offset="0.915121" stop-color="#F57E27" />
                <stop offset="0.953787" stop-color="#F7932D" />
                <stop offset="1" stop-color="#FBAE48" />
            </linearGradient>
            <linearGradient id="paint1_linear_1091_4191" x1="8" y1="17.9435" x2="28" y2="17.9435"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A3494" />
                <stop offset="0.0549206" stop-color="#4F3795" />
                <stop offset="0.0924392" stop-color="#583C98" />
                <stop offset="0.2" stop-color="#6A479C" />
                <stop offset="0.364138" stop-color="#81499D" />
                <stop offset="0.450668" stop-color="#94469B" />
                <stop offset="0.5" stop-color="#A14299" />
                <stop offset="0.647401" stop-color="#CC3293" />
                <stop offset="0.780331" stop-color="#EE236C" />
                <stop offset="0.818455" stop-color="#EF4B5E" />
                <stop offset="0.859048" stop-color="#F2624E" />
                <stop offset="0.915121" stop-color="#F57E27" />
                <stop offset="0.953787" stop-color="#F7932D" />
                <stop offset="1" stop-color="#FBAE48" />
            </linearGradient>
            <clipPath id="clip0_1091_4191">
                <rect width="20" height="20" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'SubscriptionIcon',
}
</script>