<template>
    <nav ref="sidebar" class="side-menu">
        <div class="admin-navbar">
            <div class="sidebar__body">
                <!-- Logo -->
                <div class="sidebar-logo">
                    <div class="site-logo text-center my-4">
                        <img :src="logo" alt="Klujo">
                    </div>
                </div>

                <!-- Menu -->
                <div class="sidebar__menu">
                    <SideOptions :structured="sideStructured" :compressed="!expand" />
                </div>
            </div>

            <!-- Footer -->
            <div v-if="showLoginElements" class="sidebar__footer">
                <img @click="logout" class="popover__item" :src="logoutIcon" alt="Klujo">
            </div>
        </div>
    </nav>
</template>

<script>
import mobileLogo from '@/assets/mobile-logo.png';
import API from '@/api/http'
import { SideOptions } from '@/plugins/components';
import { Popover, Button } from '@plugins/primevue-components';

import dashboardIcon from '@/assets/icons/dashboard-icon.svg';
import domainIcon from '@/assets/icons/domain-icon.svg';
import gameIcon from '@/assets/icons/game-icon.svg';
import rewardIcon from '@/assets/icons/reward-icon.svg';
import templateIcon from '@/assets/icons/template-icon.svg';
import userDataIcon from '@/assets/icons/user-data-icon.svg';
import logoutIcon from '@/assets/icons/logout-icon.svg';
import calendarIcon from '@/assets/icons/calendar-icon.svg';

export default {
    name: 'SideMenu',
    components: {
        Popover,
        Button,
        SideOptions
    },
    computed: {
        access_modules() { return this.$store.getters['User/access_modules'] },
        user() { return this.$store.getters['User/user'] },
        user_limits() { return this.$store.getters['User/user_limits'] },
        showLoginElements() {
            return (
                this.user &&
                this.user.account_plan &&
                this.user.account_plan.status === 'paid' &&
                this.$route.name !== 'AddAccount' &&
                this.$route.name !== 'Welcome'
            )
        },
        sideStructured() {
            const structured = [];
            
            if (typeof this.access_modules['games'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'DA', label: 'Dashboard', action: () => this.$router.push({ name: 'Dashboard' }), icon: dashboardIcon });
            }

            if ((typeof this.access_modules['teams'] != 'undefined' && this.user.type == 'user') || this.user.type == 'account') {
                structured.push({ code: 'DO', label: 'Domains', action: () => this.$router.push({ name: 'Domains' }), icon: domainIcon });
            }

            if (typeof this.access_modules['games'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'TE', label: 'Templates', action: () => this.$router.push({ name: 'TemplateList' }), icon: templateIcon });
                structured.push({ code: 'GA', label: 'Games', action: () => this.$router.push({ name: 'GameList' }), icon: gameIcon });
                structured.push({ code: 'GC', label: 'Calendar', action: () => this.$router.push({ name: 'GameCalendar' }), icon: calendarIcon });
            }

            if (typeof this.access_modules['rewards'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'RE', label: 'Rewards', action: () => this.$router.push({ name: 'RewardList' }), icon: rewardIcon });
            }

            if (this.user.plan.crm == 1) {
                structured.push({ code: 'UD', label: 'Player Data', action: () => this.$router.push({ name: 'CRMList' }), icon: userDataIcon });
            }

            return structured;
        }
    },
    data() {
        return {
            mediaBaseURL: import.meta.env.VITE_MEDIA_BASE_URL,
            logo: mobileLogo,
            logoutIcon: logoutIcon,
            expand: false
        }
    },
    methods: {
        logout() {
            API.logout().then(() => {
                this.$router.push({ name: 'Login' })
            }).catch(() => this.$router.push({ name: 'Login' }))
        }
    }
}
</script>

<style scoped lang="scss">
.side-menu {
    width: var(--sidebar-width);
    position: fixed;
    z-index: 1049;
    height: 100vh;
    padding: 10px;

    .admin-navbar {
        .sidebar__body {
            height: calc(100vh - 120px);
            overflow: hidden;

            .sidebar__menu {
                height: calc(100% - 100px);
            }
        }

        .sidebar__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 20px;
            cursor: pointer;

            .popover__item {
                margin: auto;
                color: #989898;
            }
        }
    }
}
</style>